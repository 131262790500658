import { Center, Image } from '@chakra-ui/react'
import { ChevronRight } from '@tofu/shared/ui/atoms/icons'
import { Link } from '@tofu/shop/ui/atoms/link'
import { Text } from '@tofu/shared/ui/atoms/text'
import { Box } from '@tofu/shared/ui/atoms/box'

import { VStack, Stack } from '@tofu/shared/ui/atoms/stack'
import { Button } from '@tofu/shared/ui/atoms/button'
import { trackClick } from '@tofu/shared/utils/analytics'
import { TrustpilotWidget } from '@tofu/shop/ui/molecules/trustpilot-widget'

import { TSliceHero } from './slice-hero.types'

export const SliceHero: TSliceHero = () => {
  return (
    <Box>
      <Box bg='brandYellow'>
        <Stack
          direction={['column', 'column', 'row', 'row', 'row']}
          justifyContent='space-between'
          color='black'
          spacing={0}
        >
          <Center
            order={[0, 0, 0, 0, 0]}
            flexShrink={0}
            px={[4, 4, 0, 0, 0]}
            display={['block', 'block', 'flex', 'flex', 'flex']}
            mt={[3, 5, 0, 0, 0]}
          >
            <Box
              ml={[0, 0, '30px', '8%']}
              position={[
                'initial',
                'initial',
                'absolute',
                'absolute',
                'absolute'
              ]}
              zIndex={10}
              left={0}
              textAlign={['left', 'center', 'left', 'left', 'left']}
            >
              <VStack
                as='h1'
                spacing={[-1, -4, -4, -8]}
                align={['start', 'center', 'start', 'start', 'start']}
                width='100%'
                p={0}
                mb={3}
                textAlign={['left', 'center', 'left', 'left', 'left']}
              >
                <Text
                  as='span'
                  p={0}
                  m={0}
                  textStyle='hero'
                  textTransform='uppercase'
                  fontSize={['22vw', '20vw', '14vw', '15vw', '185px']}
                >
                  Fuel your day
                </Text>
                <Text
                  as='span'
                  textStyle='hero-condensed'
                  p={0}
                  m={0}
                  fontSize={['18vw', '16vw', '11.2vw', '12vw', '150px']}
                >
                  With Plants
                </Text>
              </VStack>
              <Box
                as='p'
                bottom='0'
                textStyle='body-lg'
                mb={[5, 5, 10, 10, 10]}
                px={[0, '15%', 0, 0, 0]}
                width={['100%', '100%', '60%', '700px', '700px']}
              >
                Fill your freezer with 100% plant-based meals that heat up
                in&nbsp;minutes.
              </Box>
              <Button
                as={Link}
                onClick={() => trackClick('cta.hero', { location: 'SHOP NOW' })}
                href='/shop'
                size='lg'
                variant='solid'
                colorScheme='blackAlpha'
                width='auto'
                mb={[0, 0, 6, 6, 6]}
                w={['100%', 'auto', 'auto', 'auto', 'auto']}
                rightIcon={<ChevronRight boxSize={3} />}
              >
                Shop now
              </Button>
              <Box
                width={['100%', '100%', '420px', '420px']}
                transform='translateX(-5px)'
                pb={6}
                display={['none', 'none', 'block', 'block', 'block']}
              >
                <TrustpilotWidget variant='micro' />
              </Box>
            </Box>
          </Center>
          <Box>
            <picture>
              <source
                media={`(min-width: 96em)`} // 2xl ~1536px
                srcSet={`https://afsuvcxqen.cloudimg.io/v7/https://allplants.com/img/homepage/xxl.jpg?q=40&format=webp`}
              />
              <source
                width='100%'
                media={`(min-width: 80em)`} // xl ~1280px
                srcSet={`https://afsuvcxqen.cloudimg.io/v7/https://allplants.com/img/homepage/xl.jpg?q=40&format=webp`}
              />

              <source
                width='100%'
                media={`(min-width: 62em)`} // lg ~992px
                srcSet={`https://afsuvcxqen.cloudimg.io/v7/https://allplants.com/img/homepage/lg.jpg?q=40&format=webp`}
              />

              <source
                width='100%'
                media={`(min-width: 48em)`} // md ~768px
                srcSet={`https://afsuvcxqen.cloudimg.io/v7/https://allplants.com/img/homepage/md.jpg?q=40&format=webp`}
              />

              <Image
                alt='Hero'
                src={`https://afsuvcxqen.cloudimg.io/v7/https://allplants.com/img/homepage/sm.jpg?q=40&format=webp`}
              />
            </picture>
          </Box>
        </Stack>
      </Box>
      <Box mt={6} display={['block', 'block', 'none', 'none']}>
        <TrustpilotWidget variant='slim' />
      </Box>
    </Box>
  )
}
